import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Article from '../components/article'
import styled from 'styled-components'

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;

`

export const query = graphql`
  query BusinessUnitsQuery {
      site: sanitySiteSettings {
      businessUnitExcerpt
      businessUnitImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      businessUnitOrder {
        id
        title
        publishedAt
        _rawBody
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const businessUnit = (data || {}).businessUnit

  return (
    <Layout>
      <SEO title='Business Units' description={site.businessUnitExcerpt} />
      <Container>
        <Hero excerpt={site.businessUnitExcerpt} title='Business Units' mainImage={site.businessUnitImage} />
        <Wrapper>
          {site.businessUnitOrder.map(businessUnit => (
            <Article key={businessUnit.id} _rawBody={businessUnit._rawBody} mainImage={businessUnit.mainImage} title={businessUnit.title} />
          ))}
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default IndexPage
